import React from "react";
import { ReactSVG } from "react-svg";
import clsx from "clsx";
import { capitalize } from "@material-ui/core/utils";
import { withStyles } from "@material-ui/core/styles";

const styles = ({ color, size, theme }) => {
	const createStyle = (color) => {
		return {
			"& svg path": { fill: color }
		};
	};

	const createStrokeStyle = (color) => {
		return {
			"& svg": { stroke: color }
		};
	};

	return {
		root: {
			lineHeight: 0,
			"& svg": {
				height: "100%",
				width: size
			}
		},
		colorInitial: {},
		colorInherit: createStyle("currentColor"),
		colorAction: createStyle(theme.palette.action.active),
		colorDisabled: createStyle(theme.palette.action.disabled),
		colorPrimary: createStyle(theme.palette.primary.main),
		colorSecondary: createStyle(theme.palette.secondary.main),
		colorInfo: createStyle(theme.palette.info.main),
		colorSuccess: createStyle(theme.palette.success.main),
		colorWarning: createStyle(theme.palette.warning.main),
		colorError: createStyle(theme.palette.error.main),
		colorPaper: createStyle(theme.palette.background.paper),
		colorStrokeInitial: {},
		colorStrokeInherit: createStrokeStyle("currentColor"),
		colorStrokeAction: createStrokeStyle(theme.palette.action.active),
		colorStrokeDisabled: createStrokeStyle(theme.palette.action.disabled),
		colorStrokePrimary: createStrokeStyle(theme.palette.primary.main),
		colorStrokeSecondary: createStrokeStyle(theme.palette.secondary.main),
		colorStrokeInfo: createStrokeStyle(theme.palette.info.main),
		colorStrokeSuccess: createStrokeStyle(theme.palette.success.main),
		colorStrokeWarning: createStrokeStyle(theme.palette.warning.main),
		colorStrokeError: createStrokeStyle(theme.palette.error.main),
		colorStrokePaper: createStrokeStyle(theme.palette.background.paper)
	};
};

const withStylesProps = (styles) => (ParentComponent) => (props) => {
	const Component = withStyles((theme) => styles({ ...props, theme }))(ParentComponent);

	return <Component {...props} />;
};

export default withStylesProps(styles)(
	({
		src,
		color = "inherit",
		size = 24,
		classes,
		className,
		stroke = false,
		wrapper = "div",
		...other
	}) => {
		const { PUBLIC_URL } = process.env;

		return (
			<ReactSVG
				src={PUBLIC_URL + src}
				className={clsx(
					classes.root,
					stroke
						? {
								[classes[`colorStroke${capitalize(color)}`]]: color
						  }
						: {
								[classes[`color${capitalize(color)}`]]: color
						  },
					className
				)}
				wrapper={wrapper}
				{...other}
			/>
		);
	}
);
