import { LayoutStandard, parseLayout as coreParseLayout } from "@Core";
import ContentElements from "./contentElements";

const parseLayout = ({ elements, layoutTag, layoutVariant }) => {
	if (layoutVariant === "around-me") {
		return (
			<LayoutStandard fullscreen socialMedia>
				<ContentElements elements={elements}></ContentElements>
			</LayoutStandard>
		);
	}

	switch (layoutTag) {
		case "standard":
			return (
				<LayoutStandard socialMedia>
					<ContentElements elements={elements}></ContentElements>
				</LayoutStandard>
			);
		default:
			return coreParseLayout(layoutTag);
	}
};

export default parseLayout;
