import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { AppContext } from "@Core";
import ReactAroundMe from "@micado-digital/react-around-me/ReactAroundMe";
import aroundMeMarkersConfig from "../../utils/aroundMeMarkersConfig";
import aroundMeFilterIcons from "../../utils/aroundMeFilterIcons";

const useStyles = makeStyles(theme => ({
	aroundMe: {
		"& .mco-around-me__teaser-wrapper": {
			background: theme.palette.background?.light
		},
		"& .mco-around-me__teaser-title": {
			...theme.typography.h2
		},
		"& .mco-around-me__content-headline p": {
			...theme.typography.h1
		},
		"& .mco-around-me__teaser-image img": {
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0
		}
	}
}));

const AroundMe = ({ categories }) => {
	const [appData] = useContext(AppContext);
	const css = useStyles();

	return (
		<div className={css.aroundMe}>
			<ReactAroundMe
				categories={categories}
				clusterMarker={{
					size: [48, 48],
					url: "/img/elements/around-me/marker/group.svg"
				}}
				filterIcons={aroundMeFilterIcons()}
				headerHeight={210}
				lang="de"
				mainAddress={{
					latitude: appData?.latitude,
					longitude: appData?.longitude,
					mail: appData?.mail,
					phone: appData?.phone,
					street: appData?.street,
					name: appData?.name,
					city: appData?.city,
					zip: appData?.zip
				}}
				mediaFormats={{ xs: "infra" }}
				markersConfig={aroundMeMarkersConfig()}
				teaserFallback="/img/fallback.jpg"
			/>
		</div>
	);
};

export default AroundMe;
