import React, { Suspense, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { CssBaseline } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { theme as customTheme } from "./themes/hotel-jaegeralpe";
import {
	AppContext,
	BookmarkContext,
	MenuContext,
	PageContext,
	UserContext,
	UserMenuContext,
	ChangePage
} from "@Core";
import MomentUtils from "@date-io/moment";
import ReactGA from "react-ga";
import DetectPage from "./helper/detectPage";
import Ui from "@micado-digital/react-ui/components/Ui";
import "./css/index.css";

const themeDefault = createTheme(customTheme);
const themeGrey = createTheme(customTheme, {
	palette: { background: { default: "#F5F1EB" } }
});

const App = () => {
	const { REACT_APP_GOOGLE_ANALYTICS_ID } = process.env;
	const [appData, setAppData] = useState();
	const [pageData, setPageData] = useState();
	const [userData, setUserData] = useState();
	const [menuOpen, setMenuOpen] = useState(false);
	const [userMenuOpen, setUserMenuOpen] = useState(false);
	const [bookmarkContext, setBookmarkContext] = useState(
		JSON.parse(localStorage.getItem("bookmark")) || []
	);

	const queryClient = new QueryClient();

	if (REACT_APP_GOOGLE_ANALYTICS_ID) ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_ID);

	return (
		<QueryClientProvider client={queryClient}>
			<Suspense fallback={<></>}>
				<ThemeProvider theme={pageData?.theme === "grey" ? themeGrey : themeDefault}>
					<AppContext.Provider value={[appData, setAppData]}>
						<PageContext.Provider value={[pageData, setPageData]}>
							<UserContext.Provider value={[userData, setUserData]}>
								<MenuContext.Provider value={[menuOpen, setMenuOpen]}>
									<UserMenuContext.Provider value={[userMenuOpen, setUserMenuOpen]}>
										<BookmarkContext.Provider value={[bookmarkContext, setBookmarkContext]}>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<CssBaseline />
												<Router>
													<Ui>
														<ChangePage />
														<Switch>
															<Route path="/*" component={DetectPage} />
														</Switch>
													</Ui>
												</Router>
											</MuiPickersUtilsProvider>
										</BookmarkContext.Provider>
									</UserMenuContext.Provider>
								</MenuContext.Provider>
							</UserContext.Provider>
						</PageContext.Provider>
					</AppContext.Provider>
				</ThemeProvider>
			</Suspense>
		</QueryClientProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));
