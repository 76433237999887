import React, { useContext } from "react";
import clsx from "clsx";
import { AppContext } from "@Core";

import styles from "./ArrivalMap.styles";

const ArrivalMap = () => {
	const [appData] = useContext(AppContext);
	const { settings = [] } = appData || {};
	const css = styles();

	const routeBezauOpen =
		settings?.find(({ identifier }) => identifier === "route-bezau")?.value === "true";
	const routeLechOpen =
		settings?.find(({ identifier }) => identifier === "route-lech")?.value === "true";
	const routeReutteOpen =
		settings?.find(({ identifier }) => identifier === "route-reutte")?.value === "true";

	return (
		<div className={css.map}>
			<div className={css.wrapper}>
				<div className={css.wrapperBezau}>
					{routeBezauOpen ? (
						<div className={clsx(css.bezau, "open")}>
							<div className={clsx(css.label, "ok")}>
								<div>
									<svg
										height="32"
										version="1.1"
										viewBox="0 0 37 32"
										width="37"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M14.528 32.032l-14.56-11.584 2.464-3.296 11.392 9.088 20.032-26.272 3.168 2.592z"></path>
									</svg>
								</div>
								<p className={css.text}>Geöffnet</p>
							</div>
						</div>
					) : (
						<div className={clsx(css.bezau, "closed")}>
							<div className={clsx(css.label, "error")}>
								<div>
									<svg
										height="24"
										viewBox="0 0 24 24"
										width="24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M13.0607 12L19.6554 5.40533L18.5947 4.34467L12.0001 10.9393L5.40539 4.34467L4.34473 5.40533L10.9394 12L4.34473 18.5947L5.40539 19.6553L12.0001 13.0607L18.5947 19.6553L19.6554 18.5947L13.0607 12Z" />
									</svg>
								</div>
								<p className={css.text}>Geschlossen</p>
							</div>
						</div>
					)}
				</div>
				<div className={css.wrapperLech}>
					{routeLechOpen ? (
						<div className={clsx(css.lech, "open")}>
							<div className={clsx(css.label, "ok")}>
								<div>
									<svg
										height="32"
										version="1.1"
										viewBox="0 0 37 32"
										width="37"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M14.528 32.032l-14.56-11.584 2.464-3.296 11.392 9.088 20.032-26.272 3.168 2.592z"></path>
									</svg>
								</div>
								<p className={css.text}>Geöffnet</p>
							</div>
						</div>
					) : (
						<div className={clsx(css.lech, "closed")}>
							<div className={clsx(css.label, "error")}>
								<div>
									<svg
										height="24"
										viewBox="0 0 24 24"
										width="24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M13.0607 12L19.6554 5.40533L18.5947 4.34467L12.0001 10.9393L5.40539 4.34467L4.34473 5.40533L10.9394 12L4.34473 18.5947L5.40539 19.6553L12.0001 13.0607L18.5947 19.6553L19.6554 18.5947L13.0607 12Z" />
									</svg>
								</div>
								<p className={css.text}>Geschlossen</p>
							</div>
						</div>
					)}
				</div>
				<div className={css.wrapperReutte}>
					{routeReutteOpen ? (
						<div className={clsx(css.reutte, "open")}>
							<div className={clsx(css.label, "ok")}>
								<div>
									<svg
										height="32"
										version="1.1"
										viewBox="0 0 37 32"
										width="37"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M14.528 32.032l-14.56-11.584 2.464-3.296 11.392 9.088 20.032-26.272 3.168 2.592z"></path>
									</svg>
								</div>
								<p className={css.text}>Geöffnet</p>
							</div>
						</div>
					) : (
						<div className={clsx(css.reutte, "closed")}>
							<div className={clsx(css.label, "error")}>
								<div>
									<svg
										height="24"
										viewBox="0 0 24 24"
										width="24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M13.0607 12L19.6554 5.40533L18.5947 4.34467L12.0001 10.9393L5.40539 4.34467L4.34473 5.40533L10.9394 12L4.34473 18.5947L5.40539 19.6553L12.0001 13.0607L18.5947 19.6553L19.6554 18.5947L13.0607 12Z" />
									</svg>
								</div>
								<p className={css.text}>Geschlossen</p>
							</div>
						</div>
					)}
				</div>
				<div className={css.target}>
					<img alt="Logo" height={151} loading="lazy" src="/img/map-logo.webp" width={139} />
					<div className={css.pointer}></div>
				</div>
			</div>
		</div>
	);
};

export default ArrivalMap;
