import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	map: {
		background: "#0e293d",
		display: "flex",
		height: 680,
		justifyContent: "center",
		position: "relative",
		"&::after": {
			background: "#0e293d",
			bottom: 0,
			content: "''",
			left: "50%",
			position: "absolute",
			top: 0,
			transform: "translateX(-50%)",
			width: 5000,
			zIndex: -2
		},
		[theme.breakpoints.down("md")]: {
			height: 612
		},
		[theme.breakpoints.down("sm")]: {
			height: 340
		},
		[theme.breakpoints.down(460)]: {
			height: 312
		},
		[theme.breakpoints.down(374)]: {
			height: 272
		}
	},
	wrapper: {
		background: "url(/img/bg-map.webp) no-repeat left top",
		backgroundSize: "888px 680px",
		height: 680,
		left: "50%",
		position: "absolute",
		top: 0,
		transform: "translate(-50%, 0)",
		transformOrigin: "left top",
		width: 888,
		"&::after": {
			background: "url(/img/map-towns.webp) no-repeat left top",
			backgroundSize: "100% 100%",
			content: "''",
			display: "block",
			height: "100%",
			left: 0,
			position: "absolute",
			top: 0,
			width: "100%",
			zIndex: 1
		},
		[theme.breakpoints.down("md")]: {
			transform: "scale(0.9) translate(-50%, 0)"
		},
		[theme.breakpoints.down("sm")]: {
			transform: "scale(0.5) translate(-50%, 0)"
		},
		[theme.breakpoints.down(460)]: {
			transform: "scale(0.46) translate(-50%, 0)"
		},
		[theme.breakpoints.down(374)]: {
			transform: "scale(0.4) translate(-50%, 0)"
		}
	},
	bezau: {
		height: 186,
		left: -4,
		position: "absolute",
		top: 215,
		width: 352,
		"&.open": {
			background: "url(/img/map-bezau-open.webp) no-repeat left top",
			backgroundSize: "352px auto"
		},
		"&.closed": {
			background: "url(/img/map-bezau-closed.webp) no-repeat left top",
			backgroundSize: "352px auto"
		},
		"& > div": {
			left: 137,
			position: "absolute",
			top: 69
		}
	},
	lech: {
		height: 81,
		left: 249,
		position: "absolute",
		top: 399,
		width: 99,
		"&.open": {
			background: "url(/img/map-lech-open.webp) no-repeat left top",
			backgroundSize: "99px auto"
		},
		"&.closed": {
			background: "url(/img/map-lech-closed.webp) no-repeat left top",
			backgroundSize: "99px auto"
		},
		"& > div": {
			left: 68,
			position: "absolute",
			top: 37
		}
	},
	reutte: {
		height: 354,
		left: 346,
		position: "absolute",
		top: 70,
		width: 474,
		"&.open": {
			background: "url(/img/map-reutte-open.webp) no-repeat left top",
			backgroundSize: "474px auto"
		},
		"&.closed": {
			background: "url(/img/map-reutte-closed.webp) no-repeat left top",
			backgroundSize: "474px auto"
		},
		"& > div": {
			left: 282,
			position: "absolute",
			top: 302
		}
	},
	label: {
		alignItems: "center",
		background: "#0e293d",
		color: "#88c049",
		display: "flex",
		fontSize: 14,
		fontWeight: 700,
		gap: 8,
		lineHeight: 1,
		textTransform: "uppercase",
		whiteSpace: "nowrap",
		"& > div": {
			alignItems: "center",
			background: "#88c049",
			color: "#ffffff",
			display: "flex",
			height: 20,
			justifyContent: "center",
			width: 20,
			"& svg": {
				height: 12,
				width: 12,
				"& path": {
					fill: "#ffffff"
				}
			}
		},
		"&.error": {
			color: "#ff1e27",
			"& > div": {
				background: "#ff1e27"
			}
		}
	},
	target: {
		left: 302,
		position: "absolute",
		top: 235,
		zIndex: 20,
		"& img": {
			display: "block",
			position: "relative",
			width: 139
		}
	},
	pointer: {
		background: "rgba(255, 255, 255, 0.1)",
		borderRadius: "50%",
		bottom: -41,
		display: "block",
		height: 56,
		left: 17,
		padding: "21px 0 0",
		position: "absolute",
		textAlign: "center",
		width: 56,
		zIndex: -1,
		"&::after": {
			background: "#ffffff",
			border: "2px solid #0e293d",
			borderRadius: "50%",
			content: "''",
			display: "inline-block",
			height: 14,
			width: 14
		}
	}
}));

export default styles;
