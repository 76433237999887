import React from "react";
import parseElement from "../helper/parseElement";

const ContentElements = (props) => {
	const { elements } = props;

	if (!elements) return <></>;

	const parsed = elements.map((element) => {
		return <React.Fragment key={element.id}>{parseElement(element)}</React.Fragment>;
	});

	return <>{parsed}</>;
};

export default ContentElements;
