import React, { Suspense, useState } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import UISkeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FsLightbox from "fslightbox-react";

const useStyles = makeStyles((theme) => ({
	"@keyframes blink": {
		"0%": {
			opacity: 0
		},
		"50%": {
			opacity: 1
		},
		"100%": {
			opacity: 0
		}
	},
	imageWrapper: {
		cursor: "pointer",
		overflow: "hidden",
		position: "relative",
		zIndex: 1
	},
	link: {
		display: "block",
		"&:hover": {
			"& img": {
				transform: "scale(1.1)"
			},
			"& p": {
				color: theme.palette?.primary?.main
			}
		}
	},
	image: {
		display: "block",
		aspectRatio: "16 / 9",
		height: "100%",
		objectFit: "cover",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.complex
		}),
		width: "100%"
	},
	state: {
		background: theme.palette?.common?.white,
		left: theme.spacing(1),
		position: "absolute",
		top: theme.spacing(1),
		"& span": {
			fontSize: 12,
			letterSpacing: 0.4,
			textTransform: "uppercase",
			"&::before": {
				animation: `$blink 1500ms ${theme.transitions?.easing?.easeInOut} infinite`,
				background: theme.palette?.success?.main,
				borderRadius: "50%",
				content: "''",
				display: "inline-block",
				height: 8,
				marginRight: theme.spacing(1.25),
				width: 8
			}
		}
	},
	title: {
		color: theme.palette?.text?.primary,
		marginTop: theme.spacing(1.25),
		transition: theme.transitions.create("color", {
			duration: theme.transitions.duration.complex
		})
	},
	location: {
		color: theme.palette?.text?.primary,
		marginTop: theme.spacing(0.25),
		transition: theme.transitions.create("color", {
			duration: theme.transitions.duration.complex
		})
	}
}));

const Image = ({ alt, src }) => {
	const css = useStyles();

	return (
		<img
			className={clsx(css.image, "mco-webcams__item-image")}
			src={src}
			alt={alt}
			height="350"
			width="620"
			loading="lazy"
		/>
	);
};

const Webcam = ({ image, link, live, location, showLocation, skeleton, title }) => {
	const [showWebcam, setShowWebcam] = useState(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	const css = useStyles();

	if (skeleton) {
		return (
			<Box>
				<Box>
					<UISkeleton className={css.image} width="100%" variant="text" animation="wave" />
				</Box>
				<Typography className={css.title} component="p">
					<UISkeleton width="100%" variant="text" animation="wave" />
				</Typography>
				{showLocation && (
					<Typography className={css.location} component="p" variant="caption">
						<UISkeleton width="100%" variant="text" animation="wave" />
					</Typography>
				)}
			</Box>
		);
	}

	return (
		<>
			<Box className="mco-webcams__item">
				{isMobile ? (
					<a
						className={clsx(css.imageWrapper, "mco-webcams__item-image-wrapper")}
						href={link}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image alt={title} src={image} />
						{live && <Chip className={clsx(css.state, "mco-webcams__item-state")} size="small" label="Live" />}
					</a>
				) : (
					<Box
						className={clsx(css.imageWrapper, "mco-webcams__item-image-wrapper")}
						onClick={() => link && setShowWebcam(true)}
					>
						<Image alt={title} src={image} />
						{live && <Chip className={clsx(css.state, "mco-webcams__item-state")} size="small" label="Live" />}
					</Box>
				)}

				<div className="mco-webcams__item-content">
					<Typography className={clsx(css.title, "mco-webcams__item-title")} component="p">
						{title}
					</Typography>
					{showLocation && location && (
						<Typography className={clsx(css.location, "mco-webcams__item-location")} component="p" variant="caption">
							{location}
						</Typography>
					)}
				</div>
			</Box>
			<Suspense fallback={<></>}>
				<FsLightbox
					sources={[
						<iframe
							src={link}
							width="1920px"
							height="1080px"
							allow="autoplay; fullscreen"
							allowFullScreen
							title="iframe"
						/>
					]}
					toggler={showWebcam}
				/>
			</Suspense>
		</>
	);
};

export default Webcam;
