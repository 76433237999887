import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Webcam from "./webcam";

const Webcams = ({
	client,
	fallbackImage,
	gridBreakpoints,
	lang = "de",
	live = true,
	location,
	overlaySize = "large",
	region,
	selected,
	showLocation = true,
	skeletonItems = 12,
	url
}) => {
	const [data, setData] = useState();

	function isSecureURL(link) {
		try {
			const url = new URL(link);
			return url.protocol === "https:";
		} catch (err) {
			return false;
		}
	}

	useEffect(() => {
		async function fetchData() {
			const params = [];

			if (lang) {
				params.push("lang=" + lang);
			}
			if (client) {
				params.push("client=" + client);
			}
			if (region) {
				params.push("region=" + region);
			}
			if (location) {
				params.push("location=" + location);
			}
			if (selected) {
				params.push("ids=" + selected);
			}

			try {
				const result = await axios.post(url + "?" + params.join("&"), null);
				setData(result?.data);
			} catch (error) {
				setData({});
			}
		}

		fetchData();
	}, [client, lang, location, region, selected, url]);

	// no result

	if (data && data?.webcams?.length === 0) {
		return <Alert severity="error">Keine Webcams gefunden!</Alert>;
	}

	const items = data?.webcams?.map(({ id, location, imageUrl, teaserImageUrl, title, viewerUrl }) => {
		let image = fallbackImage;

		if (imageUrl && isSecureURL(imageUrl)) {
			image = imageUrl;
		} else if (teaserImageUrl && isSecureURL(imageUrl)) {
			image = teaserImageUrl;
		} else {
			image = fallbackImage;
		}

		return (
			<Grid item key={id} {...(gridBreakpoints ? { ...gridBreakpoints } : { xs: 12, sm: 6, md: 4 })}>
				<Webcam
					image={image}
					link={viewerUrl}
					live={live}
					location={location}
					overlaySize={overlaySize}
					showLocation={showLocation}
					title={title}
				/>
			</Grid>
		);
	});

	const skeleton = [...Array(skeletonItems)].map((x, i) => (
		<Grid item key={i} {...(gridBreakpoints ? { ...gridBreakpoints } : { xs: 12, sm: 6, md: 4 })}>
			<Webcam showLocation={showLocation} skeleton />
		</Grid>
	));

	return (
		<Grid className="mco-webcams" container spacing={3}>
			{items ? items : skeleton}
		</Grid>
	);
};

Webcams.propTypes = {
	client: PropTypes.string.isRequired,
	fallbackImage: PropTypes.string,
	gridBreakpoints: PropTypes.object,
	lang: PropTypes.string,
	live: PropTypes.bool,
	location: PropTypes.string,
	overlaySize: PropTypes.oneOf(["xsmall", "small", "medium", "large", "xlarge"]),
	region: PropTypes.string.isRequired,
	selected: PropTypes.string,
	showLocation: PropTypes.bool,
	skeletonItems: PropTypes.number,
	url: PropTypes.string.isRequired
};

export default Webcams;
